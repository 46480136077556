import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { withTranslation } from 'react-i18next'
import {
  Container,
 
} from 'reactstrap'

const NotFoundPage = ({t}) => (
  <Layout noFooter={true}>
    <SEO title="404: Not found" />
    <Container>
      <div className='not-found'>
        <h1>404 / NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... :(</p>
      </div>
    </Container>
    
    
  </Layout>
)

export default withTranslation()(NotFoundPage)
